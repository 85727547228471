.cradio {
  display: flex;
  align-items: center;
  min-width: 110px;

  &.ellipsis {
    & > span:nth-child(2) {
      width: 100%;
    }
  }

  &.ant-radio-wrapper-disabled {
    * {
      cursor: default;
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: rgba(17, 17, 17, 0.25) !important;
      background: rgba(17, 17, 17, 0.05);
    }
    .ant-radio-inner {
      border-width: 1px;
    }

    .promo__radio--row {
      color: #333333 !important;
    }
  }

  &--row {
    width: 180px;
  }
}

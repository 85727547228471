.clabel {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.ellipsis {
    .clabel__content {
      width: 100%;
    }

    .clabel__text {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__text {
    line-height: 24px;
  }

  &__required {
    font-size: 14px;
    line-height: 18px;
    color: rgba(17, 17, 17, 0.25);
  }
}

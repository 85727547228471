.filter-row {
  &__collapse {
    .ant-collapse-content-box {
      padding-left: 0 !important;
      padding-top: 0 !important;
    }
  }
}

.with-popover {
  width: 100%;
  &__content {
    width: 100%;
  }

  .ant-space-item:first-child {
    flex: auto;
  }
}

.layout {
  min-height: 100vh;
  &__sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
  }

  &__logo {
    a {
      height: 56px;
      color: white;
      text-decoration: none;
      display: flex;
      padding-left: 24px;
      align-items: center;
      font-size: 30px;

      transition: 0.3s ease;
      &:hover {
        color: #1890ff;
      }
    }
  }

  &__header {
    position: fixed;
    z-index: 1;
    width: calc(100% - 300px);
    padding: 0;
    height: 60px;
  }

  &__with-sider {
    margin-left: 300px;
  }

  &__content {
    margin-top: 60px;
    padding: 20px;
    overflow: initial;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  &__user {
    color: white;
    font-size: 18px;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  &__leave {
    background: transparent;
    li {
      border-bottom: none !important;
    }
  }
}
